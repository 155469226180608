import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CondidateServiceService {
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',

     
    })
    
  };
  

  private actionUrl: string;

  constructor(private http: HttpClient) {
      this.actionUrl = `${environment.server}${environment.apiUrl}`;
  }

 


  addI(user: UserI): Observable<any> {
    const result =  this.http.post(this.actionUrl, user, this.httpOptions);
    //console.log(result)
    return result;
    
  }

  addP(user: UserP): Observable<any> {
    const result =  this.http.post(this.actionUrl, user, this.httpOptions);
    //console.log(result)
    return result;
    
  }


  getCandidateByEmail(email : string): Observable<any> {
    const result =  this.http.get(this.actionUrl+'?email='+email,this.httpOptions);
    //console.log(result)
    return result;
   } 

}


export interface UserP  {
  email: string;
  fullName: string;
  phone: number;
  region: string;
  job: string;
  phase: number;
  project: Project;
  miscellaneous :Miscellaneous
}


export interface UserI  {
  email: string;
  fullName: string;
  phone: number;
  region: string;
  job: string;
  phase: number;
  idea: Idea;
  miscellaneous :Miscellaneous;
}

export interface Project  {
  name: string;
  sector: string;
  period: number;
  legalStructure: string;
  fundingType: string;
  collaboratorNumber: number;
  description: string;
  potential: string;
  challenge: string;
}

export interface Idea  {
  sector: string;
  description: string;
  potential: string;
  challenge : string;
}

export interface Miscellaneous  {
  program: string;
  similarProgramParticipation: number;
  wishes: Wishe[];
  needs: Need[];
}

export interface Wishe  {
  name: string;

}

export interface Need  {
  name: string ;

}