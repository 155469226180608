import { Component, OnInit, ViewChild, forwardRef, ViewEncapsulation } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormGroup, Validators, NG_VALUE_ACCESSOR, FormArray, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { Idea, Project, Miscellaneous, UserP, UserI, Wishe, Need } from '../Service/condidate-service.service';
import { CondidateServiceService } from '../Service/condidate-service.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';




interface Region {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
  encapsulation: ViewEncapsulation.None


})
export class FormsComponent implements OnInit {



  @ViewChild('stepper') stepper: MatStepper;
  readonly maxSize = 104857600;
  constructor(
    private formBuilder: FormBuilder,
    private condidateServiceService: CondidateServiceService,
    private _snackBar: MatSnackBar
  ) { }

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  projectValue = new FormControl();
  ideaValue = new FormControl();



  needs = [{ name: "Une équipe" }, { name: "Encadrement" }, { name: "Un espace de travail" }, { name: 'Autre :  ' }]
  wishes = [{ name: "Élargir votre réseau" },
  { name: "Apprendre à gérer votre entreprise plus efficacement" },
  { name: "Trouver des mentors" },
  { name: "Apprendre à mieux communiquer" },
  { name: "Autre :  " },
]

  type = [{name :"project"}, {name :"idea"}]

  situationProfChaoisi: string;
  situationProfessionel: string[] = ['Étudiant', 'Employé', 'Entrepreneur', 'Freelancer','autre'];



  phaseChaoisi: number;
  phases: number[] = [1,2,3];

  yesNo: number;
  YesNoQuestion: number[] = [0, 1];

  regions: Region[] = [
    { value: 'ARIANA', viewValue: 'Gouvernorat de l Ariana' },
    { value: 'BEJA', viewValue: 'Gouvernorat de Béja' },
    { value: 'BEN AROUS', viewValue: 'Gouvernorat de Ben Arous' },
    { value: 'BIZERTE', viewValue: 'Gouvernorat de Bizerte' },
    { value: 'GABES', viewValue: 'Gouvernorat de Gabes' },
    { value: 'GAFSA', viewValue: 'Gouvernorat de Gafsa' },
    { value: 'JENDOUBA', viewValue: 'Gouvernorat de Jendouba' },
    { value: 'KAIROUAN', viewValue: 'Gouvernorat de Kairouan' },
    { value: 'KASSERINE', viewValue: 'Gouvernorat de Kasserine' },
    { value: 'KEBILI', viewValue: 'Gouvernorat de Kébili' },
    { value: 'KEF', viewValue: 'Gouvernorat du Kef' },
    { value: 'MAHDIA', viewValue: 'Gouvernorat de Mahdia' },
    { value: 'MANOUBA', viewValue: 'Gouvernorat de la Manouba' },
    { value: 'MEDENINE', viewValue: 'Gouvernorat de Médenine' },
    { value: 'MONASTIR', viewValue: 'Gouvernorat de Monastir' },
    { value: 'NABEUL', viewValue: 'Gouvernorat de Nabeul' },
    { value: 'SFAX', viewValue: 'Gouvernorat de Sfax' },
    { value: 'SIDI BOUZID', viewValue: 'Gouvernorat de Sidi Bouzid' },
    { value: 'SILIANA', viewValue: 'Gouvernorat de Siliana' },
    { value: 'SOUSSE', viewValue: 'Gouvernorat de Sousse' },
    { value: 'TATAOUINE', viewValue: 'Gouvernorat de Tataouine' },
    { value: 'TOZEUR', viewValue: 'Gouvernorat de Tozeur' },
    { value: 'TUNIS', viewValue: 'Gouvernorat de Tunis' },
    { value: 'ZAGHOUAN', viewValue: 'Gouvernorat de Zaghouan' }
  ];
  @ViewChild('form') ngForm: NgForm;

  ngOnInit() {
    
    this.firstFormGroup = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', Validators.pattern( "^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$")],
      Tel: [null, Validators.minLength(8)],
      Region: ['', Validators.required],
      situationProf: ['', Validators.required],
      phase: ['', Validators.required],

    });

    this.secondFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      collaboratorNumber: [Validators.required],
      sector: ['', Validators.required],
      description: ['', Validators.required],
      potential: ['',Validators.required],
      challenge: ['',Validators.required],

    });




    this.thirdFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      collaboratorNumber: [Validators.required],
      sector: [Validators.required],
      period: ['', Validators.required],
      legalStructure: ['', Validators.required],
      fundingType: ['', Validators.required],
      description: ['', Validators.required],
      potential: ['',Validators.required],
      challenge: ['',Validators.required],

    });


    this.fourFormGroup = this.formBuilder.group({
      needs:  new FormArray([]),
      need:  ['', Validators.required],
      similarProgramParticipation: ['', Validators.required],
      program: [''],
      wish:  ['', Validators.required],
      wishes: new FormArray([]),
    });



        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.fourFormGroup.reset();

  }




  idea: Idea;
  project: Project;
  miscellaneous: Miscellaneous;
  first: any;
  second: any;
  third: any;
  four: any;
  userP: UserP;
  userI: UserI;
  fileName: any;
  isVideoType = false;
  isIdea = true;
  isProject = true;
  isExist = false;
  loading = false;
  wordCount :number = 0;
  rest : number;
  saved = false;

  disabledP = false;
  disabledI = false;
  wish :Wishe;
  need:Need;
  
  saveOptions(x) {
    
    this.wishes[4].name=x

  }

  saveOptionsNeed(x) {
    
    this.needs[3].name=x

  }
  move(index: number) {
    
    this.stepper.selectedIndex = index;
  }

 /*  fileEvent(fileInput: Event) {

    let file = (fileInput.target as HTMLInputElement).files[0];
    console.log()
    if (isVideo(file.name) == true) {
      this.isVideoType = false;
      console.log(' lena',this.isVideoType)
      getBase64(file).then(
        data => {


          this.fileName = data;
          this.fileName = this.fileName.slice(22);
          console.log(this.fileName)
        }
      );
    }
    else {
      
      this.isVideoType = true;
      console.log(this.isVideoType)
    }

  } */


 
  onKey(event: any){

    this.condidateServiceService.getCandidateByEmail(event.target.value).subscribe(res => {
      if(res.status == "Candidate with provided email already submitted the application form"){
        this.isExist = true;
        
      }
    });      
    this.isExist = false;

  }
  onKey2(event: any){

   
      

  }

/*   countWords(event : any){
    var res = event.target.value.split(/\s+/);
    this.wordCount = res.length - 1;
    this.rest = 30 - this.wordCount;
    console.log(this.wordCount);

  } */

  onTaskAdd(event){
    if(event.target.value == 'autre' || event.target.value == 'Oui')
    event.target.value =''
  }


  addForm() {
    this.loading = true;

    this.first = this.firstFormGroup.value;
    this.second = this.secondFormGroup.value;
    this.third = this.thirdFormGroup.value;
    this.four = this.fourFormGroup.value;


    this.idea = {
      sector: this.second.sector,
      description: this.second.description,
      potential: this.second.potential,
      challenge: this.second.challenge,
    };



    this.project = {
      name: this.third.name,
      sector: this.third.sector,
      period: this.third.period,
      legalStructure: this.third.legalStructure,
      fundingType: this.third.fundingType,
      collaboratorNumber: this.third.collaboratorNumber,
      description: this.third.description,
      potential: this.third.potential,
      challenge: this.third.challenge,
    };

   
    this.miscellaneous = {
      needs: this.four.needs.map(item =>({ name: item})),
      program: this.four.program,
      similarProgramParticipation: this.four.similarProgramParticipation,
      wishes: this.four.wishes.map(item =>({ name: item}))      
    };
    
  
    if(!this.isProject){

      this.userI = {
        email: this.first.Email,
        fullName: this.first.Name,
        phone: this.first.Tel,
        region: this.first.Region,
        job: this.first.situationProf,
        phase : this.first.phase,
        idea: this.idea,
        miscellaneous: this.miscellaneous
  
      };

      let need :Need = { name: ' ' };
      need.name =  this.four.need;

      let wish :Wishe = { name: ' ' };
      wish.name =  this.four.wish;

      if(need != null && wish == null){
       
        for (var i in this.userI.miscellaneous.needs) {
         
        if( this.userI.miscellaneous.needs[i].name == 'Autre :  ')
        {
         this.userI.miscellaneous.needs[i]=need;
         break; //Stop this loop, we found it!
        }
         
       }
       
      }
      else  if(need == null && wish != null){
        for (var i in this.userI.miscellaneous.wishes) {
         
          if( this.userI.miscellaneous.wishes[i].name == 'Autre :  ')
          {
           this.userI.miscellaneous.wishes[i]=wish;
           break; //Stop this loop, we found it!
          }
           
         }
      } else  if(need != null && wish != null){
        for (var i in this.userI.miscellaneous.needs) {
         
          if( this.userI.miscellaneous.needs[i].name == 'Autre :  ')
          {
           this.userI.miscellaneous.needs[i]=need;
           break; //Stop this loop, we found it!
          }
           
         };

         for (var i in this.userI.miscellaneous.wishes) {
         
          if( this.userI.miscellaneous.wishes[i].name == 'Autre :  ')
          {
           this.userI.miscellaneous.wishes[i]=wish;
           break; //Stop this loop, we found it!
          }
           
         };         

      }
      

      
      this.condidateServiceService.addI(this.userI).subscribe(res => {
        console.log(res)
        this.loading = false;
        this._snackBar.open('Saved Successfully', 'End now', {
          duration: 10000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['blue-snackbar']
        });

        
        //console.log(res)
      });
    }

    else if(!this.isIdea){

      this.userP = {
        email: this.first.Email,
        fullName: this.first.Name,
        phone: this.first.Tel,
        region: this.first.Region,
        job: this.first.situationProf,
        phase : this.first.phase,
        project: this.project,
        miscellaneous: this.miscellaneous
  
      };
      
    
      let need :Need = { name: '' };
      need.name =  this.four.need;

      let wish :Wishe = { name: '' };
      wish.name =  this.four.wish;

      if(need != null && wish == null){
       
        for (var i in this.userP.miscellaneous.needs) {
         
        if( this.userP.miscellaneous.needs[i].name == 'Autre :  ')
        {
         this.userP.miscellaneous.needs[i]=need;
         break; //Stop this loop, we found it!
        }
         
       }
       
      }
      else  if(need == null && wish != null){
        for (var i in this.userP.miscellaneous.wishes) {
         
          if( this.userP.miscellaneous.wishes[i].name == 'Autre :  ')
          {
           this.userP.miscellaneous.wishes[i]=wish;
           break; //Stop this loop, we found it!
          }
           
         }
      } else  if(need != null && wish != null){
        for (var i in this.userP.miscellaneous.needs) {
         
          if( this.userP.miscellaneous.needs[i].name == 'Autre :  ')
          {
           this.userP.miscellaneous.needs[i]=need;
           break; //Stop this loop, we found it!
          }
           
         };

         for (var i in this.userP.miscellaneous.wishes) {
         
          if( this.userP.miscellaneous.wishes[i].name == 'Autre :  ')
          {
           this.userP.miscellaneous.wishes[i]=wish;
           break; //Stop this loop, we found it!
          }
           
         };         

      }

      
      this.condidateServiceService.addP(this.userP).subscribe(res => {
        this.loading = false;
        this._snackBar.open('Saved Successfully', 'End now', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        //console.log(res)

        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.fourFormGroup.reset();
        
      });

     
     
    }

   
    
    
  }





  form1() {
    //console.log(this.firstFormGroup.value);
   // this.first = this.firstFormGroup.value;


  }

  form2() {
   // console.log(this.secondFormGroup.value);
  }

  form3() {
   // console.log(this.thirdFormGroup.value);
  }

  

  form4() {
   // console.log(this.wishes)

    
  /*   console.log( this.four.need)
    this.need.name=this.four.need;
    console.log(this.need); */
   /*  this.four.needs.push(this.need);
    console.log( this.four.needs) */
  }


selectedAutreNeed=false;
selectedAutreWish=false;

  onCheckChange(event) {

    if (event.target.checked && event.target.value=='Autre :  ') {
      this.selectedAutreNeed =true;
     }
     else {
       this.selectedAutreNeed=false
     }

    const formArray: FormArray = this.fourFormGroup.get('needs') as FormArray;
    
    
    /* Selected */
    if (event.target.checked) {
     
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
    if (event.target.unselected) {

    }

  }



  onWishChange(event) {

    if (event.target.checked && event.target.value=='Autre :  ') {
      this.selectedAutreWish =true;
     }
     else {
       this.selectedAutreWish=false
     }
    const formArray: FormArray = this.fourFormGroup.get('wishes') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }

  }


  onTypeChange() {

   
    

    
    /* Selected */
   
      if(this.ideaValue.value == true && this.projectValue.value == null){
        this.isProject = false;
        this.isIdea = true;
        this.disabledP = true;
      }
      else if(this.ideaValue.value == false && this.projectValue.value == null){
        this.isProject = true;
        this.isIdea = true;
        this.disabledP = false;
      }
      else if (this.ideaValue.value == false && this.projectValue.value == true){
        this.isIdea = false;
        this.isProject = true;
       this.disabledI = true;
      }
      else if (this.projectValue.value==true && this.ideaValue.value==null ){
        this.isIdea = false;
        this.isProject = true;
        this.disabledI = true;
      }
      else if (this.projectValue.value==false && this.ideaValue.value==null ){
        this.isIdea = true;
        this.isProject = true;
        this.disabledI = false;
      }
      else if (this.projectValue.value == false && this.ideaValue.value == false ){
        this.isProject = true;
        this.isIdea = true;
        this.disabledI = false;
        this.disabledP = false;
      }
      else if (this.projectValue.value == false && this.ideaValue.value == true ){
        this.isProject = false;
        this.isIdea = true;
        this.disabledP = true;
      }
    } 
   

  
}


export function fileExtensionValidator(validExt: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = true;
    if (control.value) {
      var fileExt = control.value + '';
      fileExt = fileExt.split('.').pop();
      validExt.split(',').forEach(ext => {
        if (ext.trim() == fileExt) {
          forbidden = false;
        }
      });
    }
    return forbidden ? { 'inValidExt': true } : null;
  };
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
  }
  return false;
}