<br>


<!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
  <button mat-raised-button color="accent" (click)="move(0)">1st</button>
  <button mat-raised-button color="accent" (click)="move(1)">2nd</button>
  <button mat-raised-button color="accent" (click)="move(2)">3rd</button>
  <button mat-raised-button color="accent" (click)="move(3)">4th</button>
</div> -->


<mat-progress-spinner style="margin:0 auto;" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>

<br>
<br>

<div fxLayoutAlign="center center" fxLayout="column" class="col-12 container px-5">

  <mat-vertical-stepper class="container align-items-center mx-auto content col-lg-8 col-md-10 col-sm-12 vivify fadeIn"
    #stepper>
    <div>
      <mat-step [completed]="false" label="information personnel">


        <form [formGroup]="firstFormGroup" (ngSubmit)="form1()" #formone="ngForm">

          <ng-template matStepLabel> Veuillez remplir vos information personnel</ng-template>

          <mat-form-field>
            <mat-label>Nom & Prénom</mat-label>
            <input matInput placeholder="Nom & Prénom" class="placeholder" formControlName="Name" required>
          </mat-form-field>

          <br>

          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input matInput placeholder="E-mail" (change)="onKey($event)" formControlName="Email" required>
          </mat-form-field>
          <mat-label style="color: red;" *ngIf="this.isExist === true">
            Le candidat avec l'e-mail fourni a déjà soumis le formulaire de candidature
          </mat-label>

          <br>

          <mat-form-field>
            <mat-label>Numéro Téléphone</mat-label>
            <input matInput placeholder="+216 " formControlName="Tel" required>
          </mat-form-field>

          <br>

          <mat-form-field>
            <mat-label>Région</mat-label>
            <mat-select matNativeControl formControlName="Region" required style="width: 200px">
              <mat-option *ngFor="let region of regions" [value]="region.value">
                {{region.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br>
          <br>
          <br>

        

          <label class="lableLefted" style="color: white;" id="example-radio-group-label">Situation Professionelle </label>
          <mat-radio-group class="lableLefted" formControlName="situationProf" aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="situationProfChaoisi">
            <mat-radio-button  class="example-radio-button lableLefted" *ngFor="let sp of situationProfessionel" [value]="sp">
              {{sp}}
            </mat-radio-button>
            
          </mat-radio-group>

          <mat-form-field *ngIf='situationProfChaoisi==="autre"'>
            <mat-label  class="lableLefted">Situation Professionelle </mat-label>
            <input (click)="onTaskAdd($event)" matInput placeholder="Votre Situation professionel ?" value=null formControlName="situationProf" required>
          </mat-form-field>

          <br>
          <br>
         



          <label class="lableLefted" style="color: white;" id="example-radio-group-label">Vous êtes dans quelle phase ?  </label>
          <mat-radio-group  formControlName="phase" aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="phaseChaoisi">
            <mat-radio-button  class="example-radio-button lableLefted" *ngFor="let sp of phases" [value]="sp">
              <span *ngIf='sp==1'> L'idéation </span>
              <span *ngIf='sp==2'> Prototypage</span>
              <span *ngIf='sp==3'> La création Juridique</span>
            </mat-radio-button>
            <br>
            <br>
            <br>

            
          </mat-radio-group>
          <label class="typess lableLefted">sélectionner le type de votre candidature : </label>
          <br>
          <br>
          <div>
            <mat-slide-toggle  class="lableLefted" [formControl]="projectValue" style="color: white;" [disabled]="disabledP"
              (change)="onTypeChange()"> Projet</mat-slide-toggle>
            <br>
            <br>
            <mat-slide-toggle  class="lableLefted" [formControl]="ideaValue" style="color: white;" [disabled]="disabledI"
              (change)="onTypeChange()"> Idée</mat-slide-toggle>

          </div>
          <br>
          <br>


          <div>
            <button mat-button matStepperNext [disabled]="firstFormGroup.invalid" >suivant</button>
          </div>
        </form>

      </mat-step>












      <mat-step [completed]="false" *ngIf="isIdea" label="Step 2">
        <form [formGroup]="secondFormGroup" (ngSubmit)="form2()" #formtwo="ngForm">
          <ng-template matStepLabel>IDEE </ng-template>
          <mat-form-field>
            <mat-label>Le nom de votre Idée ?</mat-label>
            <input matInput placeholder="Le nom de votre Idée ?" formControlName="name" required> 
          </mat-form-field>
          <br>


          <mat-form-field>
            <mat-label>Le nombre de collaborateurs de votre Idée ? </mat-label>
            <input matInput type="number" min="0" placeholder="Le nombre de collaborateurs de votre Idée ? "
              formControlName="collaboratorNumber" required>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Quel est le secteur de votre Idée ?</mat-label>
            <mat-select #Widget formControlName="sector" style="width: 200px; left: 0">
              <mat-option value='IT'>IT ( Web, Mobil, JA , Blockchain , IOT)</mat-option>
              <mat-option value='E-commerce'>E-commerce</mat-option>
              <mat-option value='Entrepreneuriat social'>Entrepreneuriat social</mat-option>
              <mat-option value='autre'>autre</mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field *ngIf="Widget.value=='autre'">
            <input matInput (click)="onTaskAdd($event)" placeholder="Vous avez sélectionné 'autre'" formControlName="sector" required>
          </mat-form-field>

          <br>
      

          <mat-form-field>
            <mat-label>Parles nous de votre Idée </mat-label>
            <textarea matInput placeholder="Parles nous de votre Idée ! " formControlName="description" required></textarea>
          </mat-form-field>

          <br>


          <mat-form-field>
            <mat-label>Parlez-nous du potentiel de votre idée ?  </mat-label>
            <textarea matInput placeholder="Parlez-nous du potentiel de votre Idée  "
               formControlName="potential" required ></textarea>

          </mat-form-field>
          <br>

          <mat-form-field>
            <mat-label>Quels sont vos challenges actuels ?  </mat-label>
            <textarea matInput placeholder="Quels sont vos challenges actuels .. "
               formControlName="challenge" required></textarea>

          </mat-form-field>

          <br>

          <div>
            <button mat-button matStepperPrevious>Retourner</button>
            <button mat-button matStepperNext >Suivant</button>
          </div>

        </form>

      </mat-step>















      <mat-step [completed]="false" *ngIf="isProject" label="Step 3">
        <form [formGroup]="thirdFormGroup" (ngSubmit)="form3()" #formthree="ngForm">
          <ng-template matStepLabel>PROJET </ng-template>

          <mat-form-field>
            <mat-label>Nom de votre projet</mat-label>
            <input matInput placeholder="Nom de votre projet" formControlName="name" required>
          </mat-form-field>

          <br>

          <mat-form-field>
            <mat-label>Le nombre de collaborateurs de votre projet ? </mat-label>
            <input matInput type="number" min="0" placeholder="Le nombre de collaborateurs de votre projet ? "
              formControlName="collaboratorNumber" required>
          </mat-form-field>
          <br>

          <mat-form-field>
            <mat-label>Quel est le secteur de votre projet ?</mat-label>
            <mat-select #WidgetSecteur formControlName="sector" style="width: 200px; left: 0">
              <mat-option value="IT">IT ( Web, Mobil, JA , Blockchain , IOT)</mat-option>
              <mat-option value='e-commerce'>E-commerce</mat-option>
              <mat-option value='Entrepreneuriat_social'>Entrepreneuriat social</mat-option>
              <mat-option value='autre'>autre</mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field *ngIf="WidgetSecteur.value=='autre'">
            <input matInput (click)="onTaskAdd($event)"  placeholder="Vous avez sélectionné 'autre'" formControlName="sector" required>
          </mat-form-field>

          <br>
          <br>


          <mat-form-field>
            <mat-label>Depuis quand vous avez lancé votre projet ?</mat-label>
            <mat-select formControlName="period" style="width: 100px; left: 0">
              <mat-option [value]=1> <= 1 an </mat-option>
                  <mat-option [value]=2>entre 1 an et 2 ans</mat-option>
                  <mat-option [value]=3>> 2 ans</mat-option>
            </mat-select>

          </mat-form-field>


          <br>
          <br>


          <mat-form-field>
            <mat-label>Quel est la structure juridique de votre projet ?</mat-label>
            <mat-select #idstructure formControlName="legalStructure" style="width: 100px; left: 0">
              <mat-option value='SARL'> SARL </mat-option>
              <mat-option value='SURL'>SURL</mat-option>
              <mat-option value='SA'> SA </mat-option>
              <mat-option value='autre'> Autre </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field *ngIf="idstructure.value==='autre'">
            <input matInput (click)="onTaskAdd($event)" placeholder="Vous avez sélectionné 'autre'" formControlName="legalStructure" required>
          </mat-form-field>


          <br>
          <br>


          <mat-form-field>
            <mat-label>Comment avez-vous financé votre projet ?</mat-label>
            <mat-select #idWidget formControlName="fundingType" style="width: 100px; left: 0">
              <mat-option value='auto_financement'>Auto financement</mat-option>
              <mat-option value='pret_bancaire'>Prêt bancaire</mat-option>
              <mat-option value='autre'>Autre</mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field *ngIf="idWidget.value==='autre'">
            <input matInput (click)="onTaskAdd($event)" placeholder="Vous avez sélectionné 'autre'" formControlName="fundingType" required>
          </mat-form-field>
          <br>

       
          <mat-form-field>
            <mat-label>Parlez-nous de votre projet. </mat-label>
            <textarea matInput placeholder="Décrivez-nous votre projet " formControlName="description" required></textarea>
          </mat-form-field>

          <br>

          <mat-form-field>
            <mat-label>Parlez-nous du potentiel de votre projet. </mat-label>
            <textarea matInput placeholder="Parlez-nous du potentiel de votre projets"
               formControlName="potential" required></textarea>
          </mat-form-field>
          <br>

          <mat-form-field>
            <mat-label>Quels sont vos challenges actuels ?  </mat-label>
            <textarea matInput placeholder="Quels sont vos challenges actuels .. "
               formControlName="challenge" required></textarea>

          </mat-form-field>
        
          <br>
          <br>

          <div>
            <button mat-button matStepperPrevious>Retourner</button>
            <button mat-button matStepperNext >Suivant</button>
          </div>

        </form>

      </mat-step>














      <mat-step [completed]="false" label="Final step">

        <form [formGroup]="fourFormGroup" (ngSubmit)="form4()" #formfour="ngForm">
          <ng-template matStepLabel>Dernière étape </ng-template>


          <mat-label  class="lableLefted" style="color: #6FC7Da;">Quels sont vos besoins actuels? </mat-label>
          <br>
          <br>
          <br>
          <div *ngFor="let data of needs; let i=index" class="col-md-2">
            <label style="color: white;" class="wishes">
              <input type="checkbox" [value]="data.name" (change)="onCheckChange($event)">
              
              {{data.name}}
            </label>

          </div>   
          
   
          <br>


          <mat-form-field  *ngIf="selectedAutreNeed">
            <input  matInput   (keyup)="saveOptionsNeed(autreNeed.value);"  placeholder="Vous avez sélectionné 'autre'" formControlName="need" #autreNeed name="need" required>
          </mat-form-field>
          <br>
          <br>

         

          
          <label  class="lableLefted" style="color: #6FC7Da;" id="example-radio-group-label">Avez-vous déjà participé à un programme similaire? </label>
          <mat-radio-group  formControlName="similarProgramParticipation" aria-labelledby="example-radio-group-label" class="example-radio-group"
          [(ngModel)]="yesNo">
          <mat-radio-button  class="example-radio-button lableLefted" *ngFor="let sp of YesNoQuestion" [value]="sp">
            <span *ngIf='sp==1'> Oui</span>
            <span *ngIf='sp==0'> Non</span>
          </mat-radio-button>
          
        </mat-radio-group>

          <mat-form-field *ngIf='yesNo==1'>
            <mat-label>Si oui lequel ? </mat-label>
            <input (click)="onTaskAdd($event)" matInput placeholder="Si oui lequel ?" formControlName="program" required>
          </mat-form-field>




          <br>
          <br>

          <mat-label class="lableLefted" style="color: #6FC7Da;">Pendant le programme Your Idea is your Spark, vous souhaitez : </mat-label>
          <br>
          <br>
          <div *ngFor="let data of wishes; let i=index" class="col-md-2">
            <label style="color: white;" class="wishes">
              <input type="checkbox" [value]="data.name"  (change)="onWishChange($event)">
              {{data.name}}
            </label>
          </div>
          <br>
          <mat-form-field  *ngIf="selectedAutreWish">
            <input  matInput (keyup)="saveOptions(autre.value);"  placeholder="Vous avez sélectionné 'autre'" formControlName="wish" #autre name="wish" required>
          </mat-form-field>

         
        </form>
        <br>
        <br>

        <div>
          <button class=" btnValider d-flex mx-auto  " mat-button type="button" [disabled]="firstFormGroup.invalid && fourFormGroup.invalid "
          (click)="addForm();firstFormGroup.reset();secondFormGroup.reset();thirdFormGroup.reset();stepper.reset()">
         Valider
        </button>
        <br>
        <button class="d-flex mx-auto btnR " mat-button matStepperPrevious>Retourner</button>

        </div>

      </mat-step>



     
    </div>
  </mat-vertical-stepper>


</div>