<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<!-- Footer -->
<section id="footer" class="vivify fadeIn">
	<div class="container">

		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
				<ul class="list-unstyled list-inline social text-center">
					<li class="list-inline-item"><a href="https://www.facebook.com/Hive12.Coworking.Space/" target="_blank"><i
								class="fa fa-facebook"></i></a></li>
					<li class="list-inline-item"><a href="https://www.instagram.com/hive12.coworking.space/" target="_blank"><i
								class="fa fa-instagram"></i></a></li>
					<li class="list-inline-item"><a href="https://www.linkedin.com/company/hive12/" target="_blank"><i
								class="fa fa-linkedin"></i></a></li>
					<!-- 						<li class="list-inline-item"><a href="https://contact@hive12.com" ><i class="fa fa-envelope"></i></a></li>
 -->
					<li class="list-inline-item"><a href="https://twitter.com/Hive12S" target="_blank"><i class="fa fa-twitter"></i></a></li>
					<li class="list-inline-item"><a href="https://www.youtube.com/channel/UCmMJJJIojHVBfn3LS2hmxDw" target="_blank"><i class="fa fa-youtube"></i></a></li>

				</ul>
			</div>
			<hr>
		</div>
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
				<!--<p><u><a href="https://www.nationaltransaction.com/">National Transaction Corporation</a></u> is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN]</p>-->
				<p>© All right Reversed 2020. Created By<a style="font-weight: bolder;" href="https://beecoop.co"
						target="_blank"> Beecoop Agency </a></p>
			</div>
			<hr>
		</div>
	</div>
</section>
<!-- ./Footer -->