<mat-toolbar>

  <!-- <img class="logo" src="assets/img/logo.jpg" alt="">   -->

  <!-- <div class="title">Hive12 spark program </div> -->
  <div class="row mx-auto text-center d-flex ">
    <img class=" topLogo vivify popIn img-fluid col-3 mx-auto " src="../../assets/img/Logo Hive 12.svg" alt="">
    <br>
    <br>
    
    <img class=" botLogo vivify popIn img-fluid col-12" src="../../assets/img/Logo 2.svg" alt="">
  </div>

</mat-toolbar>
<mat-card class="vivify fadeIn">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
      <!--  <div>Author: Beecoop</div> -->

    </div>

    <section class="container x-5">
      <h1 class="descT"> Description</h1>
      <div class="desc ">

        Dans le cadre du Programme Spark Hive12 lance un appel à candidature " Your Idea is your Spark " pour
        sélectionner 10 projets/ idées de projet pour bénéficier d'un programme d'incubation intensif d'une durée de 3
        mois.
        <br>
        <br>
        Notre approche intervention mix entre des bootcamps intensifs, des sessions de workshops en présentiel, des
        sessions de formation en mode digital et des sessions de pitch avec feed-back comme accompagnement.
        <br>
        <br>
        Ainsi, en se basant sur le travail collaboratif, les entrepreneurs vont bénéficier d’un échange constructive qui
        va
        leurs permettre de reproduire la démarche (itération) et retravailler l’ idée qu’il prévoit de réaliser en
        fonction
        des feedbacks collectés. Cette méthode a pour avantage d’être agile et réaliste. C’est pour cette raison qu’elle
        réussira à mieux convaincre les investisseurs, mais également à avoir une meilleure visibilité sur la viabilité
        du
        projet.
        <br>
        <br>
        Les 3 big challenges :
        <br>
        - Gain du temps : Des entrepreneurs qui savent optimiser et gérer leurs temps.
        <br>
        - Learning by doing : Des entrepreneurs qui apprennent en pratiquant, en suivant un process sur mesure et avec
        beaucoup des challenges.
        <br>
        - Auto-building : Des entrepreneurs autonomes, créatifs et visionneurs
        <br>
        <br>
        <span style="font-weight: bolder;"> DDL pour l'appel à candidatures : 15/09/2020 à 00:00</span>
        


      </div>
    </section>
  </mat-card-content>



</mat-card>